<template>
    <v-container>
        <v-row justify="center" class="py-5 text-center">
            <v-col cols="12" sm="12" md="10" lg="8" xl="8" class="pa-0" style="text-align: center">
                <p class="mt-2 mb-6 px-2" :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline', 'font-weight-light']" style="line-height: 1.5">Live free.</p>
                <!-- <p class="mt-2 mb-6 px-2" :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline', 'font-weight-light']" style="line-height: 1.5">Freedom-loving software.</p> -->
                <!-- <p class="my-2 px-2" :class="[$vuetify.breakpoint.mdAndUp ? 'text-body-1' : 'text-body-2', 'font-weight-light']" style="line-height: 1.5">Get the information you need.</p> -->

                <!-- A/B test: "Do you need a reliable Internet presence for your website or server? Do you need a reliable, independent DNS resolver for your website or server? We made Liberty Infrasystems for you.". -->
                <p class="my-2 px-2" :class="[$vuetify.breakpoint.mdAndUp ? 'text-body-1' : 'text-body-2', 'font-weight-light']" style="line-height: 1.5">We believe that everyone deserves essential personal productivity software that respects their freedom and privacy. We are developing a variety of easy-to-use software applications and online services.</p>
                <!-- <p class="my-2 px-2" :class="[$vuetify.breakpoint.mdAndUp ? 'text-body-1' : 'text-body-2', 'font-weight-light']" style="line-height: 1.5">We are developing a variety of easy-to-use software applications and online services.</p> -->

                <p class="my-2 px-2" :class="[$vuetify.breakpoint.mdAndUp ? 'text-body-1' : 'text-body-2', 'font-weight-light']" style="line-height: 1.5">Publishing software with an open source license is a cornerstone of enabling personal freedom and privacy. Developing an economic system that helps developers and creators get paid for their work is critical to maintaining the open source software and continuing to make progress for everyone's benefit.</p>
                    <p class="my-2 px-2" :class="[$vuetify.breakpoint.mdAndUp ? 'text-body-1' : 'text-body-2', 'font-weight-light']" style="line-height: 1.5">The source code for client and server software that we develop will be publicly available with 1) a free and open source license, 2) instructions on downloading, building, installing, and configuring the software, and 3) community resources to support usage, maintenance, and further development of the software.</p>

                <p class="my-2 px-2" :class="[$vuetify.breakpoint.mdAndUp ? 'text-body-1' : 'text-body-2', 'font-weight-light']" style="line-height: 1.5"></p>
            </v-col>
        </v-row>
            <v-row justify="center" class="py-2 mb-6">
                <v-col cols="12" sm="12" md="10" lg="8" xl="8" class="pa-0"  style="text-align: center">
                    <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline', 'font-weight-light']">Get involved!</h1>
                    <!-- This section is commented out because libertybase doesn't have collection of checkboxes yet ... so enable it when we can create the form we need. also, later the form should be replaced with an entire web app for looking at submitted ideas, voting on them , submitting new ones. -->
                    <!-- <p class="my-2"><span :class="[$vuetify.breakpoint.mdAndUp ? 'headline' : 'title', 'font-weight-light']" style="line-height: 1.5">Tell us what you'd like to see.</span></p>

                    <v-btn href="https://entry.libertybase.io/form/tbd" class="mt-8" color="purple white--text">
                        See ideas
                    </v-btn> -->

                    <p class="mb-2 mt-6"><span :class="[$vuetify.breakpoint.mdAndUp ? 'headline' : 'title', 'font-weight-light']" style="line-height: 1.5">Are you a developer or creator looking for a way to make the world a better place?</span></p>

                    <!-- A/B test "Create a form", "Let's go!", "Try it now", "Sign up" -->
                    <v-btn href="https://entry.libertybase.io/form/06H6NY2KXQCNB26B8H50" class="mt-8" color="purple white--text">
                        Raise hand
                    </v-btn>

                </v-col>
            </v-row>
    </v-container>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        ...mapGetters({
            currentAccount: 'account', // currently selected account object
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    methods: {
        init() {
            if (this.isReady && this.isAuthenticated) {
                this.$router.push('/dashboard');
            }
        },
    },
    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
